import React from 'react';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { IconButton, Box } from '@material-ui/core';

export const PrevArrow = (props: any) => {
  const { onClick, classes } = props;

  return (
    <Box className={classes}>
      <IconButton size="small" onClick={onClick}>
        <ArrowLeft />
      </IconButton>
    </Box>
  );
};

export const NextArrow = (props: any) => {
  const { onClick, classes } = props;

  return (
    <Box className={classes}>
      <IconButton size="small" onClick={onClick}>
        <ArrowRight />
      </IconButton>
    </Box>
  );
};
