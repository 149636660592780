import React from 'react';
import Container from '../components/Container';
import Infogerance from '../components/PagesComponent/Infogerance/Infogerance';
import SEO from '../components/seo';

function InfogerancePage() {
  return (
    <Container>
      <SEO
        title="Hairun Technology - L'infogérance"
        description="Notre but est de vous accompagner dans la mise en place de vos projets web informatiques, concordant avec vos objectifs et vos cibles."
      />
      <Infogerance />
    </Container>
  );
}

export default InfogerancePage;
