import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconAws = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_6138"
        data-name="Groupe 6138"
        transform="translate(-21.855 -293.274)"
      >
        <path
          id="Tracé_16553"
          data-name="Tracé 16553"
          d="M64.23,320.616a62.8,62.8,0,0,1-30.467-7.838,61.3,61.3,0,0,1-11.442-8.161,1.834,1.834,0,0,1-.309-.34.722.722,0,0,1,.8-1.132,2.921,2.921,0,0,1,.755.364,81.129,81.129,0,0,0,13.986,6.311,85.888,85.888,0,0,0,15.815,3.808,81.679,81.679,0,0,0,16.651.721,84.133,84.133,0,0,0,27.094-6.243c.218-.092.435-.187.663-.265a1.4,1.4,0,0,1,1.687.575,1.25,1.25,0,0,1-.5,1.618c-.938.711-1.918,1.36-2.921,1.979a57.373,57.373,0,0,1-16.5,6.716,63.149,63.149,0,0,1-15.315,1.887"
          transform="translate(0 23.618)"
          fill="#f49817"
        />
        <path
          id="Tracé_16554"
          data-name="Tracé 16554"
          d="M54.083,313.732c.146-.1.129-.241.16-.357q2.351-9.1,4.7-18.2c.364-1.4.707-1.676,2.169-1.683.66,0,1.319-.01,1.976,0,.785.017,1.03.289.894,1.047a28.03,28.03,0,0,1-.959,3.264q-2.984,9.62-5.991,19.232c-.445,1.438-.741,1.676-2.244,1.67a18.039,18.039,0,0,1-2.268-.051c-.806-.1-1.061-.286-1.279-1.1-.262-.972-.486-1.958-.721-2.938q-1.806-7.5-3.608-15.006a.909.909,0,0,0-.19-.4c-.15.609-.3,1.214-.449,1.826q-1.6,6.687-3.2,13.37c-.211.887-.418,1.775-.636,2.656a6.378,6.378,0,0,1-.221.724,1.183,1.183,0,0,1-1.18.847q-1.515.015-3.03,0a1.2,1.2,0,0,1-1.149-.772,20.122,20.122,0,0,1-.792-2.394q-2.163-7.079-4.308-14.162c-.643-2.118-1.3-4.237-1.914-6.362-.34-1.166-.1-1.462,1.091-1.452.87.007,1.738-.034,2.6.051a1.168,1.168,0,0,1,1.061.755,10.84,10.84,0,0,1,.5,1.69l4.335,17.076a.959.959,0,0,0,.19.408c.136-.558.275-1.115.4-1.673q1.724-7.3,3.445-14.611c.2-.857.4-1.721.626-2.571.211-.785.483-.993,1.3-1.071a15.086,15.086,0,0,1,2.853,0,1.236,1.236,0,0,1,1.306,1.125c.272,1.088.52,2.183.779,3.271q1.811,7.676,3.621,15.346l.116.442"
          transform="translate(18.841 0.494)"
          fill="#fff"
        />
        <path
          id="Tracé_16555"
          data-name="Tracé 16555"
          d="M43.324,306.376c0,1.585-.02,3.173.01,4.754a20.949,20.949,0,0,0,.194,2.3,8.608,8.608,0,0,0,1.02,2.744c.432.857.34,1.231-.456,1.761-.49.327-.976.656-1.469.979a1.207,1.207,0,0,1-1.877-.238,9.7,9.7,0,0,1-1.479-2.288c-.255-.517-.265-.527-.646-.112a10.27,10.27,0,0,1-5.328,3.193,10.145,10.145,0,0,1-5.764-.2,6.4,6.4,0,0,1-4.342-4.75,7.795,7.795,0,0,1,6.253-9.97,16.65,16.65,0,0,1,6.223-.041c.887.136,1.761.323,2.642.5.2.037.381.068.377-.218a18.7,18.7,0,0,0-.2-4.019c-.483-2.142-1.6-3.142-3.893-3.458a15.131,15.131,0,0,0-7.423,1c-.551.2-1.1.425-1.659.6s-.826,0-.9-.551a7.225,7.225,0,0,1,.051-2.343c.092-.449.459-.656.819-.847a15.725,15.725,0,0,1,3.1-1.183,17.916,17.916,0,0,1,5.107-.694,15.673,15.673,0,0,1,3.87.469,7.111,7.111,0,0,1,5.5,5.825,12.008,12.008,0,0,1,.272,2.588c-.01,1.4,0,2.805,0,4.206Zm-4.56,2.89c0-.211-.014-.422,0-.633.02-.275-.1-.4-.364-.459a19.4,19.4,0,0,0-6.668-.425,4.186,4.186,0,0,0-3.377,2.078,5.015,5.015,0,0,0-.364,3.393,3.363,3.363,0,0,0,2.9,2.765,7.9,7.9,0,0,0,4-.48,5.271,5.271,0,0,0,3.669-4.029,10.933,10.933,0,0,0,.2-2.21"
          transform="translate(2.477 0.033)"
          fill="#fff"
        />
        <path
          id="Tracé_16556"
          data-name="Tracé 16556"
          d="M49.3,319.9a18.8,18.8,0,0,1-7.3-1.353,5.4,5.4,0,0,1-.853-.452,1.505,1.505,0,0,1-.748-1.374c.014-.517-.007-1.037,0-1.557.014-.687.343-.939,1.013-.741a10.722,10.722,0,0,1,1.139.432A15.944,15.944,0,0,0,49.924,316a5.416,5.416,0,0,0,3.567-1.391A3.033,3.033,0,0,0,53.032,310a8.866,8.866,0,0,0-1.9-.881c-1.653-.537-3.319-1.041-4.968-1.585a8.63,8.63,0,0,1-3.183-1.755,6.8,6.8,0,0,1-2.305-4.754c-.214-3.373,2.064-5.934,5.121-7.059a13.08,13.08,0,0,1,6.342-.537,16.452,16.452,0,0,1,3.431.806c.109.037.214.071.32.116,1.472.595,1.775,1.044,1.775,2.622a4.144,4.144,0,0,1-.071,1.044.6.6,0,0,1-.666.5,1.863,1.863,0,0,1-.765-.211,13.305,13.305,0,0,0-6-1.211,7.66,7.66,0,0,0-3.036.578,2.757,2.757,0,0,0-1.734,2.431,2.832,2.832,0,0,0,1.517,2.829,20.947,20.947,0,0,0,4.271,1.632,22.548,22.548,0,0,1,4.74,1.816,6.184,6.184,0,0,1,3.122,5.253,7.449,7.449,0,0,1-5.2,7.437,12.209,12.209,0,0,1-4.529.83"
          transform="translate(44.512 0)"
          fill="#fff"
        />
        <path
          id="Tracé_16557"
          data-name="Tracé 16557"
          d="M52.256,302.615a16.428,16.428,0,0,1,4.441.568c1.221.374,1.452.66,1.479,1.945a17.116,17.116,0,0,1-1.381,6.7,15.334,15.334,0,0,1-4.043,6.056,2.155,2.155,0,0,1-.551.388.614.614,0,0,1-.707-.048.574.574,0,0,1-.078-.666c.411-1.071.84-2.132,1.231-3.213a26.538,26.538,0,0,0,1.513-5.447,6.008,6.008,0,0,0,.037-1.132,1.439,1.439,0,0,0-1.017-1.394,7.753,7.753,0,0,0-2.394-.4,48.231,48.231,0,0,0-7.375.459,4.064,4.064,0,0,1-.67.048.483.483,0,0,1-.435-.765,1.6,1.6,0,0,1,.585-.592,14.911,14.911,0,0,1,6.219-2.265,23.725,23.725,0,0,1,3.145-.241"
          transform="translate(48.898 22.421)"
          fill="#f49817"
        />
      </g>
    </SvgIcon>
  );
};

export default IconAws;
