import React from 'react';

import classnames from 'classnames';

import styles from './style';

import ReactHtmlParser from 'react-html-parser';

import { Box, Typography, ListItem, ListItemIcon } from '@material-ui/core';
import Layout from '../../../Common/Layout';
import SlickSlider from '../../../Common/SlickSlider';
import { PrevArrow, NextArrow } from '../../../Common/SlickSlider/Arrow';

import AmazonWeb from './Logs_img/amazon-web-services-2.svg';
import ansibleDevops from './Logs_img/ansible.svg';
import dockerLogoDocker from './Logs_img/docker-official.svg';
import gitlabLogo from './Logs_img/gitlab.svg';
import googleCloud from './Logs_img/google_cloud.svg';
import grafana from './Logs_img/grafana.svg';
import kubernetesLogo from './Logs_img/kubernetes.svg';
import MicrosoftAzure from './Logs_img/MicrosoftAzure.png';
import Prometheus_logo_color from './Logs_img/prometheus.svg';
import terraform from './Logs_img/terraform.svg';


const ConfiancesData = [
  {
    id: 1,
    title: 'AmazonWeb',
    image: AmazonWeb,
  },
  {
    id: 2,
    title: 'ansibleDevops',
    image: ansibleDevops,
  },
  {
    id: 3,
    title: 'dockerLogoDocker',
    image: dockerLogoDocker,
  },
  {
    id: 4,
    title: 'gitlabLogo',
    image: gitlabLogo,
  },
  {
    id: 5,
    title: 'googleCloud',
    image: googleCloud,
  },
  {
    id: 6,
    title: 'grafana',
    image: grafana,
  },
  {
    id: 7,
    title: 'kubernetesLogo',
    image: kubernetesLogo,
  },

  {
    id: 8,
    title: 'MicrosoftAzure',
    image: MicrosoftAzure,
  },
  {
    id: 9,
    title: 'prometheus',
    image: Prometheus_logo_color,
  },
  {
    id: 10,
    title: 'terraform',
    image: terraform,
  },


];

interface PropTypes {
  title: string;
}
const Confiances = ({ title }: PropTypes) => {
  const classes = styles();

  const slickSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    // autoplaySpeed: 3000,
    arrows: false,
    prevArrow: (
      <PrevArrow
        classes={classnames(
          classes.confiancesSlickArrow,
          classes.confiancesSlickPrev,
        )}
      />
    ),
    nextArrow: (
      <NextArrow
        classes={classnames(
          classes.confiancesSlickArrow,
          classes.confiancesSlickNext,
        )}
      />
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box id="bloc-confiance" className={classes.confiancesWrapper}>
      <Box className={classes.background} />
      <Layout>
        <Box>
          {/* <Typography
            variant="h2"
            className={classnames('titreSection', classes.confiancesTitle)}
          >
            {ReactHtmlParser(title)}
          </Typography> */}
          <SlickSlider
            settings={slickSettings}
            classesnames={classes.confiancesList}
          >
            {ConfiancesData.map(({ id, image, title }) => (
              <ListItem key={id} className={classes.confiancesListItem}>
                <ListItemIcon className={classes.confiancesListItemIcon}>
                  <img src={image} alt={title} />
                </ListItemIcon>
                {/* <p>{title}</p> */}
              </ListItem>
            ))}
          </SlickSlider>
        </Box>
      </Layout>
    </Box>
  );
};

export default Confiances;
