import React, { useEffect, useState } from 'react';
import styles from './style';

import {
  Card,
  CardHeader,
  CardMedia,
  Typography,
  Button,
  Box,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import IconArrowRightAlt from '../../Common/Icons/IconArrowRightAlt';
import IconLogiciel from '../../Common/Icons/IconLogiciel';
import IconSite from '../../Common/Icons/IconSite';

import img1 from '../../../images/infogerance/img_infogerance.svg';
import img2 from '../../../images/infogerance/img_serverless.svg';
import img3 from '../../../images/infogerance/img_devops.svg';
import BgElementGauche from '../../../images/infogerance/element_gauche.svg';

import devsecops from '../../../images/devsecops.svg';



import Techno from '../../PagesComponent/Home/Techno/Techno'
import gtiClock from '../../../images/infogerance/gti.svg';
import serviceIcon from '../../../images/infogerance/service.svg';
import compatibleIcon from '../../../images/infogerance/compatible.svg';
import { useTheme } from '../../Container';

import IconAws from '../../Common/Icons/IconAws';
import IconAzure from '../../Common/Icons/IconAzure';
import IconDigitalOcean from '../../Common/Icons/IconDigitalOcean';
import IconGoogleCloud from '../../Common/Icons/IconGoogleCloud';
import kubern from '../../Common/Icons/kubern.png';
import IconKubernetes from '../../Common/Icons/Kubernetes';
import TerraForm from '../../Common/Icons/TerraForm';
import Docker from '../../Common/Icons/Docker';

import ReactHtmlParser from 'react-html-parser';
import ScrollTo from '../../Common/ScrollTo';

import Layout from '../../Common/Layout';
import classnames from 'classnames';
import ContactForm from '../../Common/ContactForm';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Techonologies from '../../Common/Technologies';
import evolutivite from '../../../images/evolutivite.svg';
import fiabilite from '../../../images/fiabilite.svg';
import infrastructure_as_code from '../../../images/infrastructure_as_code.svg';
import kubernetes_clustering from '../../../images/kubernetes_clustering.svg';
import microservice_architecture from '../../../images/microservice_architecture.svg';
import productivite from '../../../images/productivite.svg';
import rapidite from '../../../images/rapidite.svg';
import serverless_architecture from '../../../images/serverless_architecture.svg';
import set_up_cicd_pipelines from '../../../images/set_up_cicd_pipelines.svg';

const services2 = [
  {
    id: 1,
    title:
      // '<span>Cloud</span> Computing <br/> <span>Serverless</span> Computing',
      " Des solutions de <span>cloud computing </span>adaptées aux besoins de votre entreprise",
      
    items: [
      'Les solutions de cloud computing offrent à votre entreprise une évolutivité illimitée, une meilleure utilisation des ressources, une efficacité maximale et surtout un coût optimisé. Notre équipe de spécialistes du cloud vous aidera à tirer parti de tous les avantages de ces solutions :',
      
      " <span>SaaS : (Software as a Service) </span>",

      "- Déployer une application sans se soucier des infrastructures. ",
      "- Minimiser le Time To Market de votre projet digital ",

      "<span>IaaS: (Infrastructure as a Service) </span>",

      "- Réduit les coûts liés au serveur et au stockage des données",
      "- Optimise la maintenance du système et permet de rester flexible sur le marché.",

      "<span>PaaS: (Platform as a Service) </span>",

      "- Une solution rapide pour gérer la mise à l'échelle de votre service en cas de montée ou de baisse de charge.",
      
    ],
    icon: <IconLogiciel width="48" height="48" viewBox="0 0 48 48" />,
    image: img2,
  },
  {
    id: 1,
    // title: 'Solution <span>DevOps</span>',
    title: 'Solutions <span> DevOps externalisées chez HaiRun Technology</span>',
    items: [
      "Notre but est vous aider à économiser du temps et de l'argent grâce à nos pratiques et services DevOps. Faites appel à nos experts DevOps pour une automatisation des tâches, une intégration et une livraison continues (CI/CD) de votre application, une architecture microservice,  une intégration au cloud, un infrastructure as code ainsi qu'une multitude d'autres services.En combinant la compétence en génie logiciel et l'infrastructure, nous contribuons au cycle de développement depuis la phase initiale à la phase de production.",
    ],
    icon: <IconSite width="48" height="48" viewBox="0 0 48 48" />,
    image: img3,
  },
];

const TechnologiesItem2 = [
  {
    id: 1,
    icon: <IconAws width="85.223" height="50.96" viewBox="0 0 85.223 50.96" />,
    title: 'IconAws',
  },
  {
    id: 2,
    icon: (
      <IconAzure width="177.666" height="50.96" viewBox="0 0 177.666 50.96" />
    ),
    title: 'IconAzure',
  },
  {
    id: 3,
    icon: (
      <IconGoogleCloud
        width="325.599"
        height="50.961"
        viewBox="0 0 325.599 50.961"
      />
    ),
    title: 'IconGoogleCloud',
  },
  {
    id: 4,
    icon: (
      <IconKubernetes
        width="80"
        height="30"  
        viewBox="0 0 325.599 50.961"
      />
    ),
    title: 'IconKubernetes',
  },
  {
    id: 5,
    icon: (
      <TerraForm
        width="70"
        height="50"
        viewBox="0 0 176.884 50.961"
      />
    ),
    title: 'TerraForm',
  },
  {
    id: 6,
    icon: (
      <Docker
        width="40"
        height="25"
        viewBox="85.223 50.96"
      />
    ),
    title: 'Docker',
  },  {
    id: 7,
    icon: (
      <Docker
        width="40"
        height="25"
        viewBox="0 0 48 48"
      />
    ),
    title: 'Docker',
    image: img3,
  },
];

const DataCard2 =
[

{
  title: "Rapidité ",
  image: rapidite,

},
{
  title: "Fiabilité ",
  image: fiabilite,

},
{
  title: "Productivité ",
  image: productivite,

},
{
  title: "Evolutivité ",
  image: evolutivite,

}

]

const DataCard =
[
{
  id:1,
  title: "Kubernetes Clustering : ",
  image: kubernetes_clustering,
  icons:kubernetes_clustering,
  description:"Faites du potentiel des conteneurs une réalité opérationnelle en exécutant des applications conteneurisées sur plusieurs machines et environnements.",
},
{
  id:2,
  title: "Configurer les pipelines CI/CD",
  description: "Concevez et créez des pipelines d'intégration et de livraison continues pour automatiser votre processus de livraison de logiciels via AWS CodePipeline.",
  image: set_up_cicd_pipelines,
  icons:set_up_cicd_pipelines,
},
{
  id:3,
  title: "Architecture de microservices:",
  description: "Faites évoluer la pile technologique d'une application complexe avec une architecture de microservice tout en permettant une livraison rapide, fréquente et fiable. L'objectif est de disposer d'une application facile à maintenir.",
  image: microservice_architecture,
  icons:microservice_architecture,
},
{
  id:4,
  title: "Serverless Architecture:",
  description: "Créez et exécutez des applications sans gérer l'infrastructure, en exécutant des applications sur des serveurs, mais sans avoir besoin de provisionnement, de mise à l'échelle et de maintenance.",
  image: infrastructure_as_code,
  icons:infrastructure_as_code,
},
{
  id:5,
  title: "Infrastructure as code:",
  description: "Provisionnez et gérez les ressources cloud en écrivant un modèle lisible par l'homme et consommable par la machine.  L'avantage est de versionner l'infrastructure du cloud et de simplifier la révision.",
  image: serverless_architecture,
  icons:serverless_architecture ,
},
{
  id:6,
  title: "DevSecOps:",
  description: "Intégrez les pratiques de sécurité dans le cycle de vie du développement logiciel pour garantir la sécurité  ",
  image: devsecops,
  icons:devsecops,
},
]




const Infogerance = () => {
  const devis = useTheme();
  const handleDevis = () => {
    devis.toggle();
  };
  const classes = styles();
  const [showScrollTo, setShowScrollTo] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    setShowScrollTo(offset > 1000);
  };
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Box id="infogeranceId" className={classes.cardOfferSection}>
      {showScrollTo ? <ScrollTo element="/infogerance/#infogeranceId" /> : null}
      <Layout className={classes.cardOffersContainer}>
        <Box className={classes.cardOfferWrapper}>
          <Card className={classnames(classes.cardRoot)}>
            <Box classes={{ root: classes.card }} component="div">
              <CardHeader
                title="Notre société d'infogérance informatique externalisée à votre service!"
                className={classnames(classes.cardTitle, classes.cardTitleTop)}
                subheader="Nos experts en infogérance informatique accompagnent différents types d'entreprises (petites et moyennes entreprises, entreprises intermédiaires ou collectivités). 
                Nous mettons nos compétences techniques au service de votre entreprise. 
                Notre objectif est de maintenir en condition opérationnelle 24/7/365 vos infrastructures serveurs, stockage et réseaux hébergés."
              />
            </Box>
            <CardMedia
              classes={{ root: classes.cardMedia1 }}
              image={img1}
              title="card media"
              style={{ maxWidth: 706 }}
            />
          </Card>
        </Box>
      </Layout>
      <Box className={classes.garantie}
        bgcolor="#0E0C38"
      >
        <Layout className={classes.containerGarantie}>
          <Box className={classes.titleGarantie}>
            <Typography variant="h2" className={classnames('titreSection')}>
              <span>Nos services d'externalisation</span> d'infogérance <span> comprennent:</span>
            </Typography>
            <ul>
              <li className={classnames('textParagraphe')}>
                <ChevronRightIcon className={classes.OtherOffersItemListIcon} />
                Supervision des infrastructures serveurs, stockage et réseau hébergées.
              </li>
              <li className={classnames('textParagraphe')}>
                <ChevronRightIcon className={classes.OtherOffersItemListIcon} />
                Administration et maintenance preventive
              </li>
              <li className={classnames('textParagraphe')}>
                <ChevronRightIcon className={classes.OtherOffersItemListIcon} />
                Sécurisation reseaux et systèmes
              </li>
            </ul>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={classes.listItemsGarantie}
          >
            <Typography variant="h3">24h/24 7j/7 365/365</Typography>
            <p>Haute disponibilité de nos experts</p>
            <ul>
              <li className={classnames('textParagraphe')}>
                <img src={gtiClock} alt="GTI icon" /> 
                GTI de 15 minutes
              </li>
              <li className={classnames('textParagraphe')}>
                <img src={serviceIcon} alt="Service icon" />
                Support 24/7
              </li>
              <li className={classnames('textParagraphe')}>
                <img src={compatibleIcon} alt="compatible icon" />
                Compatible DevOps & MLOps
              </li>
            </ul>
          </Box>
        </Layout>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        className={classes.accompagnement}
      >
        <Layout className={classes.containerAccompagnement}>
          <Box className={classnames(classes.titleWrapperAccompagnement)}>
            <Box>
              <Typography
                variant="h2"
                className={classnames(
                  'titreSection',
                  classes.titleAccompagnement,
                )}
              >
              Devenez plus agile grâce aux solutions DevOps <br/>de HaiRun Technology ! 
              </Typography>
              <Typography
                className={classnames(
                  'textParagraphe',
                  classes.descriptionAccompagnement,
                )}
              >
              Que vous ayez besoin d'un ingénieur DevOps ou d'une équipe complète, HaiRun Technology peut vous aider à automatiser vos applications, à réduire les délais de mise sur le marché, à accroître l'efficacité et à réduire les coûts informatiques.
              </Typography>
            </Box>
          </Box>
          <Box className={classnames(classes.contentAccompagnement)}>
            {/* <Box>
              <p>
                Nos évaluations sont basées sur une analyse approfondie de la
                situation, des tests concrets pour votre environnement IT et des
                recommandations détaillées sur les points à améliorer et sur la
                façon d’y parvenir.
              </p>
              <ul>
                <li className={classnames('textParagraphe')}>
                  <ChevronRightIcon
                    className={classes.OtherOffersItemListIcon}
                  />
                  Analyse de vos besoins
                </li>
                <li className={classnames('textParagraphe')}>
                  <ChevronRightIcon
                    className={classes.OtherOffersItemListIcon}
                  />
                  Dimensionnement de la solution
                </li>
                <li className={classnames('textParagraphe')}>
                  <ChevronRightIcon
                    className={classes.OtherOffersItemListIcon}
                  />
                  Les choix à adopter dans votre société
                </li>
                <li className={classnames('textParagraphe')}>
                  <ChevronRightIcon
                    className={classes.OtherOffersItemListIcon}
                  />
                  Installation et déploiement de la nouvelle infrastructure IT
                </li>
                <li className={classnames('textParagraphe')}>
                  <ChevronRightIcon
                    className={classes.OtherOffersItemListIcon}
                  />
                  Migration
                </li>
                <li className={classnames('textParagraphe')}>
                  <ChevronRightIcon
                    className={classes.OtherOffersItemListIcon}
                  />
                  Virtualisation de l’environnement
                </li>
                <li className={classnames('textParagraphe')}>
                  <ChevronRightIcon
                    className={classes.OtherOffersItemListIcon}
                  />
                  Déploiement dans le Cloud
                </li>
                <li className={classnames('textParagraphe')}>
                  <ChevronRightIcon
                    className={classes.OtherOffersItemListIcon}
                  />
                  Analyse
                </li>
              </ul>
              <p>
                Hairun Technology vous apporte l'expérience et l'expertise
                nécessaires pour implementer la nouvelle infrastructure IT dans
                votre entreprise dans son ensemble.
              </p>
            </Box> */}
          </Box>
        </Layout>
      <Box style={{marginTop:120, width:'100%'}}>

        <Layout>
          <Box className={classes.test}>
          <Typography variant="h2"
          className={classnames(
          'titreSection',
          classes.titleAccompagnement,
          )}   >
          Nos services d'externalisation DevOps comprennent :
          </Typography>
          <Box className={classes.contentServicesWrap}>          
            <Box className={classes.contentServices}>
                {DataCard.map(({ id, image, title,icons, description }) => (
                  <Box className={classes.wrapperCard}>          
                    <img src={image} alt={title} />
                    <Box>      
                      <Typography component='span'> {title} </Typography>
                      <Typography>{description}</Typography>
                    </Box>      
                </Box>  
                ))}

            </Box>
          </Box>
          </Box>
      </Layout>
      </Box>
      <Box bgcolor="#0E0C38" style={{marginTop:120, width:'100%',paddingTop:20}}>
        <Layout className={classes.containerMethodDevOps}>
            <Typography variant="h2"
            className={classnames(
            'titreMethod',
            classes.titleAccompagnement,
            )}>
            Optez pour la méthode DevOps:
            </Typography>
          <Box className={classes.contentMethodDevOps}>
                <Box className={classes.WrapMethodContent}>                         
                {DataCard2.map(({image, title }) => (
                <Box className={classes.WrapMethod}>          
                <img src={image} alt={title} />
                <Typography component='span' style={{color:'#fff'}}> {title} </Typography>
               </Box>  
                ))}

             
            </Box>
          </Box>
        </Layout>
      </Box>
      {/* <Box className={classes.titleWrapper} bgcolor="#12103B">
        <Box className={classes.background}>
          <img src={BgElementGauche} alt="image" />
        </Box>
        <Layout className={classes.titleWrapperContainer}>
          <Typography
            variant="h2"
            className={classnames('titreSection', classes.titleService)}
            style={{ maxWidth: 1037 }}
          >
            <span>Devenez plus agile grâce aux solutions DevOps de HaiRun Technology !</span>
            {/* <span>Solutions d'infrastructure informatique innovantes</span> */}
          {/* </Typography> */}
        {/* // </Layout> */}
      {/* // </Box> */}

      <Box id="cardOffers" className={classes.cardOfferSection}>
        <Layout className={classes.cardOffersContainer}>
          <Box className={classes.cardOfferWrapper}>
            {services2.map(({ id, title, items, icon, image }, index) => (
              <Card className={classnames(classes.cardRoot)} key={id}>
                <Box
                  classes={{ root: classes.card }}
                  component="div"
                  style={index % 2 === 0 ? { order: 1 } : { order: 2 }}
                >
                  <CardHeader
                    avatar={icon}
                    title={ReactHtmlParser(title)}
                    className={classes.cardTitle}
                  />
                  <Box className={classes.rollover}>
                    {items.map((item, index) => (
                      <Typography
                        key={index}
                        className={classnames(
                          'textParagraph',
                          classes.cardText,
                        )}
                      >
                        {ReactHtmlParser(item)}
                      </Typography>
                    ))}
                    <Box mt={2} display="flex">
                      <Button
                        className={classnames(
                          'classicButton',
                          classes.btnDemarrer,
                        )}
                        variant="outlined"
                        endIcon={<IconArrowRightAlt viewBox="0 0 24 24" />}
                        onClick={handleDevis}
                      >
                        Obtenir un devis
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <CardMedia
                  classes={{ root: classes.cardMedia }}
                  image={image}
                  title="card media"
                  style={index % 2 === 0 ? { order: 2 } : { order: 1 }}
                />
              </Card>
            ))}
          </Box>
        </Layout>
      </Box>
      {/* <Techonologies
        bgcolor="#333333"
        title="Quelques-unes<br/> <span>des</span> technologies <span>que nous maîtrisons</span>"
        description=""
        items={TechnologiesItem2}
        minWidthItem="120px"
        marginTopItem="24px"
        marginBottomItem="24px"
      /> */}

        <Box bgcolor="#fff" style={{width:'100%',paddingTop:20}}>
        <Layout className={classes.containerMethodDevOps}>
            <Typography variant="span"
            className={classnames(
            'titreMethod',
            classes.titleAccompagnement,
            )}>
           Quelques-unes<br/> <span>des</span> technologies <span>que nous maîtrisons</span>
            </Typography>
            <Box>
                <Techno/>
            </Box>

        </Layout>
      </Box>
      {!domaine_ext ?
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.com"
          formTitle="Contactez-<span>nous</span>"
        />
        :
        <ContactForm
          title="<span>Nous restons à votre</span> disposition"
          description="Pour toute question, n'hésitez pas à nous contacter."
          mailto="contact@hairun-technology.fr"
          formTitle="Contactez-<span>nous</span>"
        />}
    </Box>
    </Box>
  );
};

export default Infogerance;
