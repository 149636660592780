import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  cardOfferSection: {
    position: 'relative',
    '& .technologiesIconAws': {
      maxWidth: 90,
      width: 90,
    },
    '& .technologiesIconDigitalOcean': {
      maxWidth: 100,
    },
    '& .technologiesIconHosteur': {
      maxWidth: 150,
    },
    '& .technologiesIconGoogleCloud': {
      maxWidth: 225,
      width: 225,
    },

    '& .technologiesIconAzure': {
      maxWidth: 150,
    },
    '& .MuiList-root': {
      justifyContent: 'space-around',
    },
  },
  background: {
    position: 'absolute',
    top: '9%',
    right: 0,
  },
  cardOffersContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(9),
  },
  cardOffersTitle: {
    '&:after': {
      backgroundColor: '#D34246',
      right: 0,
      left: 'inherit',
    },
    '@media (max-width: 768px)': {
      textAlign: 'left !important',
      '&:after': {
        backgroundColor: '#D34246',
        right: 'inherit',
        left: 0,
      },
    },
  },
  cardOfferWrapper: {
    maxWidth: 1600,
    margin: '0 auto',
  },
  cardRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(15),
    '@media (max-width: 1200px)': {
      paddingTop: 20,
    },
    '@media (max-width: 768px)': {
      paddingTop: 0,
      flexWrap: 'wrap',
    },
  },
  cardOffer: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 800px)': {},
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    height: '100%',
    width: '70%',

    // padding: theme.spacing(5),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    '@media (max-width: 1200px)': {
      paddingTop: 10,
      paddingLeft: 0,
      paddingBottom: 10,
    },
    '@media (max-width: 992px)': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      order: '2 !important',
    },
    '&:hover>div:last-of-type': {
      opacity: 1,
    },
  },
  cardMedia: {
    // flex: 1,
    flexBasis: '50%',
    maxWidth: '100%',
    backgroundSize: 'contain',
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
  cardMedia1: {
    // flex: 1,
    flexBasis: '50%',
    maxWidth: '100%',
    backgroundSize: 'contain',

    '@media (max-width: 1280px)': {
      flexBasis: '40%',
    },
    '@media (max-width: 1200px)': {
      flexBasis: '45%',
    },
    '@media (max-width: 992px)': {
      display: 'none',
    },
  },
  cardContent: {
    padding: 0,
  },
  cardTitle: {
    paddingLeft: 0,
    '@media (max-width: 992px)': {
      paddingRight: 0,
      width: '100%',
    },
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      margin: 0,
      '& span': {},
      '& > .MuiCardHeader-content': {
        maxWidth: 'fit-content',
      },
    },
    '@media (max-width: 576px)': {},
    '& > .MuiCardHeader-avatar': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 77,
      height: 77,
      border: '1px solid #27272729',
      borderRadius: '50%',
      '@media (min-width: 769px) and (max-width: 991px)': {
        width: 44,
        height: 44,
      },
      '@media (max-width: 576px)': {
        width: 44,
        height: 44,
      },
      '& svg': {
        fontSize: '3rem',
        '@media (min-width: 769px) and (max-width: 991px)': {
          fontSize: '2rem',
        },
        '@media (max-width: 576px)': {
          fontSize: '2rem',
        },
      },
    },
    '& span': {
      lineHeight: 1.2,
      fontWeight: 400,
      textTransform: 'initial',
      fontFamily: 'ubuntu',
      fontSize: '2.125rem',
      '@media (min-width: 1200px) and (max-width: 1600px)': {
        fontSize: '2rem',
      },
      '@media (min-width: 992px) and (max-width: 1199px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width: 769px) and (max-width: 991px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width: 767px)': {
        fontSize: '1.5rem',
        textAlign: 'center',
      },
      '& span': {
        fontWeight: 600,
      },
      '& + span': {
        fontWeight: 300,
        fontSize: '1.25rem',
        marginTop: 20,
      },
      '& br': {
        '@media (max-width: 768px)': {
          display: 'none',
        },
      },
    },
  },
  cardTitleTop: {
    '& span': {
      fontSize: '3.5rem',
      fontWeight: 600,
      '@media (max-width: 1599px)': {
        fontSize: '3rem',
      },
      '@media (max-width: 1444px)': {
        // fontSize: '5.5rem',
      },
      '@media (max-width: 1200px)': {
        fontSize: '2.5rem',
      },
      '@media (max-width: 600px)': {
        fontSize: '3rem',
      },
      '@media (max-width: 365px)': {
        fontSize: '2.5rem',
      },
    },
  },
  cardText: {
    color: '#808080',
    marginBottom: theme.spacing(2),
    maxWidth: 684,
    textAlign:"justify",
    '@media (max-width: 992px)': {
      fontSize: '1.125rem',
      marginBottom: 5,
    },
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      textAlign: 'center',
    },
    '@media (max-width: 576px)': {},
    '& span': {
      color: '#E0363A',
      fontWeight: 600,
    },
  },
  divider: {
    width: 30,
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    color: '#fff',
    backgroundColor: 'currentColor',
  },
  rollover: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& > div': {
      textAlign: 'center',
    },
  },
  btnDemarrer: {
    fontWeight: 400,
    fontSize: '0.875rem',
    borderRadius: 4,
    borderColor: '#E0363A',
    color: '#E0363A',
    boxShadow: 'none',
    transition: '50ms ease-in',
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '@media (max-width: 360px)': {
      fontSize: '0.725rem',
    },
    '&:hover': {
      paddingLeft: 20,
      paddingRight: 20,
      transition: '50ms ease-in',
    },
    '& svg': {
      transition: '50ms ease-in',
    },
    '&:hover svg': {
      transition: '50ms ease-in',
      transform: 'translateX(5px)',
    },
  },
  accompagnement: {
    color: '#fff',
    paddingTop: 60,
    // paddingBottom: 60,
    '& p': {
      fontFamily: 'ubuntu',
    },
  },
  containerAccompagnement: {
    display: 'flex',
    // maxWidth: 1600,
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
    },
  },
  titleWrapperAccompagnement: {
    width: '100%',
    // maxWidth: 1200,
    '& > div': {
      // maxWidth: 1200,
      '@media (max-width: 1024px)': {
        // maxWidth: '100%',
      },
    },
    '& p': {
      fontSize: '1.250rem',
      color:"#808080",
      '@media (max-width: 1024px)': {
        fontSize: '1.125rem',
      },
    },
    '@media (max-width: 1024px)': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  titleAccompagnement: {
    color:"#272727",
    '&:after': {
      backgroundColor: '#D34246',
    },
  },
  descriptionAccompagnement: {},
  contentAccompagnement: {
    // width: '100%',
    fontSize: '1rem',
    '& > div': {
      // maxWidth: 580,
      marginLeft: 'auto',
      marginRight: 'auto',
      '@media (max-width: 1024px)': {
        maxWidth: '100%',
      },
    },
    '& ul': {
      paddingLeft: 0,
      listStyleType: 'none',
    },
    '& li': {
      display: 'flex',
      padding: theme.spacing(1),
      color:"#808080",
      alignItems: 'flex-start',
      '@media (max-width: 992px)': {
        padding: 5,
      },
    },
    '& p':{
      color:"#808080"
    },
    '@media (max-width: 1024px)': {
      width: '100%',
    },
  },
  OtherOffersItemListIcon: {
    backgroundColor: '#EBB32A',
    color: '#fff',
    borderRadius: '50%',
    marginRight: 10,
    marginTop: 6,
    width: 20,
    height: 20,
    '@media (max-width: 1200px)': {
      backgroundColor: '#EBB32A',
    },
  },
  garantie: {
    color: '#808080',
    fontSize: '1rem',
    '& ul': {
      paddingLeft: 0,
      listStyleType: 'none',
    },
    '& li': {
      display: 'flex',
      color:"#fff",
      padding: theme.spacing(1),
      alignItems: 'flex-start',
      fontSize: '0.9375rem',
      '@media (max-width: 992px)': {
        padding: 5,
      },
    },
    '& svg': {
      marginTop: 0,
    },
  },
  containerGarantie: {
    display: 'flex',
    maxWidth: 1480,
    alignItems: 'center',
    paddingTop: 80,
    paddingBottom: 80,
    '@media (max-width: 992px)': {
      flexDirection: 'column',
    },
  },
  titleGarantie: {
    maxWidth: 404,
    '& h2': {
      fontSize: '3rem !important',
      // color: '#272727',
      color: '#ffffff',
      lineHeight: '52px !important',
      '@media (max-width: 1200px)': {
        fontSize: '2.5rem !important',
        lineHeight: '46px !important',
      },
    },
    '@media (max-width: 992px)': {
      maxWidth: '100%',
    },
  },
  listItemsGarantie: {
    flex: 1,
    paddingLeft: 40,
    paddingRight: 40,
    '& p':{
      color:"#fff"
    },
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 60,
      '@media (max-width: 992px)': {
        marginTop: 12,
      },
      '@media (max-width: 442px)': {
        flexDirection: 'column',
      },
    },
    '& li': {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      flexDirection: 'column',
      color:"#fff",
      textAlign: 'center',
      '@media (max-width: 992px)': {
        padding: 5,
      },
    },
    '& h3': {
      fontSize: '2.125rem !important',
      fontWeight: 700,
      // color: '#272727',
      color: '#ffffff',
      marginBottom: 0,
    },
    '@media (max-width: 992px)': {
      width: '100%',
      marginTop: 32,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  iconGarantie: {},
  titleWrapperContainer: {
    maxWidth: 1664,
  },

  titleWrapper: {
    position: 'relative',
    paddingTop: 103,
    paddingBottom: 103,
    '@media (max-width: 600px)': {
      paddingTop: 64,
      paddingBottom: 42,
    },
  },
  /*background: {
    position: 'absolute',
    backgroundImage: `url(${BgCreation})`,
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    content: "''",
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },*/
  titleService: {
    // color: '#272727',
    color: '#ffffff',

    '&:after': {
      backgroundColor: '#E0363A',
      right: 0,
    },
  },
  test:{
    '& p':{
      color:'gray',
    }
  },
  contentServices:{
    display:'flex',
    flexWrap:'wrap',
    justifyContent:'center',
    '& h2':{
      fontSize:'2.6rem',
      color:'#272727'
    },
    '@media (max-width: 1200px)': {
      justifyContent:'center',
      '& img':{
        maxWidth:100,
      },
    }
  },
  contentServicesWrap:{
    display:'flex',
    flexDirection:'column',
    margin:'auto',
    width:"100%",
    flexWrap:'wrap',
    maxWidth:'1200px'
  },  
  contentDescription:{
    display:'flex',
    justifyContent:'space-between',
    fontSize:'30px',
  },
  wrapperCard:{
    display:'flex',
    width:'100%',
    maxWidth:550,
    minWidth:249,
    alignItems:'center',
    color:"#000 !important",
    margin:5,
    padding:20,
    border:'1px solid gray',
    borderRadius:10,
    '& span':{
      display:'flex',
      fontSize:'1.2rem',
      color:'#272727',
      fontWeight:'bolder'
    },
    '& img':{
      maxWidth:150,
      marginRight:10,
    },
    '@media (max-width: 768px)': {
      flexDirection:'column',
      textAlign:'justify',
      alignItems:'center',
      '& img':{
        maxWidth:100,
      },
    }
  },
  containerMethodDevOps:{
    display:'flex',
    width:'100%',
    margin:'auto',
    flexDirection:'column',
    padding:"50px 40px ", 
    '& h2':{
      color:"#fff",
      fontSize:'3.5rem'
    },
    '& span':{
      color:"#000",
      fontSize:'3.5rem'
    }
  },
  titreMethod:{
    display:'none !important'
  },
  contentMethodDevOps:{
    maxWidth:1480,
    width:'100%',
    padding:'70px 0',
    alignItems:"center",
    alignSelf:'center'
  },
  WrapMethodContent:{
    display:'flex',
    justifyContent:'space-around'
  },
  WrapMethod:{
    width: "100%",
    maxWidth: "250px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    '& span':{
      fontSize:'2rem'
    },
    '& img':{
      fill:"#fff"
    }
  }
}));
