import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconAzure = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g
        id="Groupe_6134"
        data-name="Groupe 6134"
        transform="translate(-66.678 -285.288)"
      >
        <path
          id="Tracé_16558"
          data-name="Tracé 16558"
          d="M122.115,333.941c-16.77.018-33.385-.009-50,0l-.015-.159q15.026-2.656,30.1-5.324a1.561,1.561,0,0,0-.48-.734q-7.323-8.737-14.649-17.469c-.975-1.164-.875-.71-.383-2.074Q90.47,297.687,94.3,287.2a1.309,1.309,0,0,1,.4-.734c9.116,15.789,18.22,31.555,27.416,47.471"
          transform="translate(10.548 2.301)"
          fill="#1e86c8"
        />
        <path
          id="Tracé_16559"
          data-name="Tracé 16559"
          d="M66.68,330.62a1.255,1.255,0,0,1,.3-.79q7.995-13.873,15.987-27.746a3.9,3.9,0,0,1,.875-1.052q9.1-7.61,18.182-15.232a2.259,2.259,0,0,1,.725-.513,2.43,2.43,0,0,1-.321.893q-9.948,21.346-19.905,42.686c-.268.577-.374,1.335-.9,1.659-.492.3-1.2.091-1.812.091-4.081.009-8.161,0-12.245,0Z"
          transform="translate(0 0)"
          fill="#1e86c8"
        />
        <path
          id="Tracé_16560"
          data-name="Tracé 16560"
          d="M117.441,320.4c-.9,0-1.765.012-2.628,0-1.388-.018-1.049.2-1.544-1.087-.837-2.18-1.644-4.369-2.466-6.556-.265-.7-.268-.707-.972-.707H98.664c-.722,0-.725.006-.972.7q-1.242,3.482-2.478,6.962c-.245.684-.247.686-.972.686-1-.009-1.995.038-2.985-.05-.115-.253.018-.43.088-.61q5.427-14.279,10.851-28.562a.743.743,0,0,1,.834-.589c.769.041,1.541.012,2.31.012.728,0,.728,0,.99.675q1.909,4.954,3.821,9.911,3.522,9.157,7.048,18.314a2.332,2.332,0,0,1,.242.9m-8.2-11.547a1.33,1.33,0,0,0-.171-.822c-.577-1.591-1.184-3.17-1.753-4.767-1.043-2.92-2.251-5.784-3.1-8.895-.236.742-.348,1.426-.586,2.071q-2.113,5.741-4.2,11.493a1.507,1.507,0,0,0-.206.919Z"
          transform="translate(47.75 10.305)"
          fill="#1c85c7"
        />
        <path
          id="Tracé_16561"
          data-name="Tracé 16561"
          d="M137.754,310.669c.071.916.038,1.859.024,2.793,0,.286-.274.348-.466.462a13.67,13.67,0,0,1-9.814,1.411,8.453,8.453,0,0,1-5.822-5.286,13.946,13.946,0,0,1,.265-11.09,9.08,9.08,0,0,1,6.874-5.5,8.337,8.337,0,0,1,7.932,2.313,8.93,8.93,0,0,1,2.266,4.72,19.271,19.271,0,0,1,.259,4.219c-.021.654-.027.663-.716.663q-6.665.009-13.329.012c-1.1,0-1.114,0-.969,1.12a8.353,8.353,0,0,0,.975,3.064,6.059,6.059,0,0,0,5.295,3.179,10.476,10.476,0,0,0,6.691-1.895c.139-.1.256-.259.536-.189m-13.482-8.291a1.716,1.716,0,0,0,.872.094c2.31.006,4.617,0,6.927,0,1.025,0,2.051.006,3.076,0,.625-.006.657-.032.6-.66a7.482,7.482,0,0,0-1.008-3.279,4.318,4.318,0,0,0-2.531-2.009,5.53,5.53,0,0,0-4.929.7,7.353,7.353,0,0,0-3.011,5.147"
          transform="translate(105.058 15.544)"
          fill="#1c85c7"
        />
        <path
          id="Tracé_16562"
          data-name="Tracé 16562"
          d="M121.914,311.706c-.062.08-.127.156-.18.236a7.189,7.189,0,0,1-7.283,3.306,10.663,10.663,0,0,1-1.214-.2,6.151,6.151,0,0,1-4.611-4.013,10.7,10.7,0,0,1-.734-3.836c-.038-4.363-.038-8.727-.047-13.093,0-.6.012-.613.583-.622.8-.009,1.591.006,2.386,0,.33,0,.471.13.451.457-.012.18,0,.359,0,.539q.013,5.586.029,11.167a11.1,11.1,0,0,0,.418,3.35,4.473,4.473,0,0,0,4.567,3.391,5.169,5.169,0,0,0,4.97-3,6.488,6.488,0,0,0,.8-3.173c-.021-3.927,0-7.855.006-11.782,0-.884-.08-.849.822-.849.692,0,1.385-.006,2.08,0,.6.009.6.015.619.583.006.13,0,.259,0,.386V313.8c0,.156-.015.309,0,.463.032.351-.091.522-.471.513-.846-.015-1.694-.015-2.54,0-.383.006-.483-.171-.477-.519.012-.84,0-1.676,0-2.516l-.177-.035"
          transform="translate(80.123 15.938)"
          fill="#1c85c7"
        />
        <path
          id="Tracé_16563"
          data-name="Tracé 16563"
          d="M112.988,296.5a1.909,1.909,0,0,0-.89-.094q-4.7-.018-9.4-.03c-.18,0-.359-.009-.536,0-.3.012-.407-.133-.407-.418.006-.669.006-1.335-.009-2-.006-.312.091-.492.433-.471.153.009.309,0,.463,0h14.785c.8.006,1.046.46.628,1.173a5.759,5.759,0,0,1-.356.5q-5.728,7.924-11.452,15.851a2.237,2.237,0,0,0-.457.757,1.957,1.957,0,0,0,.9.094q5.2.013,10.395.021c1.137,0,.96-.08.975,1,.009.486-.009.975,0,1.461.006.286-.106.433-.4.43l-.539,0q-7.814,0-15.63,0a.337.337,0,0,1-.077,0c-.268-.041-.645.135-.778-.153a1.142,1.142,0,0,1,.074-1.1c.124-.195.268-.377.4-.563l11.411-15.689a2.577,2.577,0,0,0,.463-.76"
          transform="translate(65.883 15.937)"
          fill="#1c85c7"
        />
        <path
          id="Tracé_16564"
          data-name="Tracé 16564"
          d="M119.785,297.724a11.252,11.252,0,0,1,1.043-1.915,5.482,5.482,0,0,1,3.235-2.325,6.814,6.814,0,0,1,2.826.012c.25.05.427.144.427.442-.006,1.022,0,2.045,0,3.029-.253.177-.395.006-.545-.056a4.44,4.44,0,0,0-5.86,2.021,9.37,9.37,0,0,0-1.243,4.626c.009,3.491-.021,6.98-.035,10.471,0,.2,0,.41-.006.616a.341.341,0,0,1-.377.383c-.9,0-1.794-.015-2.7-.024-.262,0-.354-.15-.348-.395,0-.153,0-.309,0-.46q-.013-9.816-.024-19.631c0-.1.009-.206,0-.309-.024-.318.106-.474.439-.471.846.009,1.694.015,2.54,0,.386-.006.48.186.477.522q-.009,1.313.009,2.619a1.444,1.444,0,0,0,.144.846"
          transform="translate(96.333 15.675)"
          fill="#1c85c7"
        />
      </g>
    </SvgIcon>
  );
};

export default IconAzure;
